import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';
import ViewCampaignButton from 'components/atoms/ViewCampaignButton';

interface CampaignResource {
  title?: string;
  titleColor?: 'text-aqua' | 'text-violet' | 'text-orange';
  header: string;
  body?: string;
  cta?: [{ label: string; url: string }];
  image: string;
  imageSide?: 'left' | 'right';
  centered?: boolean;
  margin?: boolean;
}

const CampaignResource: React.FC<CampaignResource> = ({
  title,
  titleColor = 'text-aqua',
  header,
  body,
  cta,
  image,
  imageSide = 'right',
  centered,
  margin = true,
  children,
}) => {
  const direction =
    imageSide === 'right' ? 'lg:flex-row' : 'lg:flex-row-reverse';
  const bodyMargin = imageSide === 'right' ? 'mx-6 xl:ml-8' : 'mx-6 xl:mr-8';
  const bodyHtml = body ? body.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';

  return (
    <div
      className={`flex flex-col-reverse items-center ${
        margin ? 'mb-16 lg:mb-24 last:mb-0' : ''
      } ${direction} ${centered ? 'text-center' : ''}`}
    >
      <div className="pt-8 md:py-8 w-full lg:w-6/12 text-center md:text-left">
        <div className={bodyMargin}>
          {title && (
            <div
              className={`mb-2.5 mt-4 lg:mt-0 font-semibold text-lg titleing-relaxed tracking-tighter ${titleColor}`}
            >
              {title}
            </div>
          )}
          <h3 className="pt-2 lg:pt-0 mb-8 u-h3 text-dark-grey">{header}</h3>
          {body && (
            <p className="text-lg lg:text-xl text-dark-grey mb-8">
              {html2react(bodyHtml)}
            </p>
          )}
          {cta?.map((ctaCampaign) => (
            <div className="mt-4">
              <ViewCampaignButton cta={ctaCampaign} />
            </div>
          ))}

          {children}
        </div>
      </div>
      <div className="w-full px-6 md:mx-0 lg:w-6/12">
        <img className="w-full h-auto" src={image} alt={header} />
      </div>
    </div>
  );
};

export default CampaignResource;
