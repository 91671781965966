import React from 'react';
import { Link } from 'gatsby';

interface ViewCampaignButtonProps {
  cta: any;
  openDownloadableFile?: any;
}

const ViewCampaignButton: React.FC<ViewCampaignButtonProps> = ({ cta }) => {
  const openDownloadableFile = () => {
    window.location.assign(cta?.file);
  };
  return (
    <>
      {!!cta?.file ? (
        <a onClick={openDownloadableFile} className="u-cta-link">
          {cta?.label}
        </a>
      ) : (
        <Link to={cta?.url} className="u-cta-link">
          {cta?.label}
        </Link>
      )}
    </>
  );
};

export default ViewCampaignButton;
