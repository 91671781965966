import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import CampaignHero from 'components/organisms/CampaignHero';
import {
  CampaignCenterSection,
  CampaignTwoColSection,
  CampaignBulletSection,
  CampaignResourcesSection,
  CampaignCtaSection,
} from 'components/organisms/CampaignSections';
import CampaignFormSection from 'components/organisms/CampaignFormSection';

const CampaignContent = ({ hero, campaignSections, forceSuccess }) => {
  const onSpeakClick = () => {
    try {
      document.getElementById('speakWithUsEnt').scrollIntoView();
    } catch {
      console.log('error');
    }
  };

  return (
    <>
      <CampaignHero
        heading={hero?.title}
        content={hero?.body}
        image={hero?.image}
        cta={hero?.cta}
        onSpeakClick={onSpeakClick}
      />
      {campaignSections.map(
        (section, index) =>
          ({
            centerSection: <CampaignCenterSection {...section} key={index} />,
            twoColSection: <CampaignTwoColSection {...section} key={index} />,
            bulletSection: <CampaignBulletSection {...section} key={index} />,
            formSection: (
              <CampaignFormSection
                {...section}
                key={index}
                forceSuccess={forceSuccess}
              />
            ),
            resourcesSection: (
              <CampaignResourcesSection {...section} key={index} />
            ),
            ctaSection: (
              <CampaignCtaSection
                {...section}
                key={index}
                onSpeakClick={onSpeakClick}
              />
            ),
          }[section?.type]),
      )}
    </>
  );
};

export const CampaignPreview = ({ entry }) => {
  const [forceSuccess, setForceSuccess] = useState(false);

  return (
    <div>
      <div
        className="text-white bg-primary px-2.5 py-1.5 my-2 rounded-full inline-block text-xs cursor-pointer"
        onClick={() => {
          setForceSuccess(!forceSuccess);
        }}
      >
        {forceSuccess ? 'Show Form' : 'Show Sucess Message'}
      </div>
      <hr />
      <CampaignContent
        {...entry.get('data').toJS()}
        forceSuccess={forceSuccess}
      />
    </div>
  );
};

const Campaign = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout simple>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <CampaignContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CampaignByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        status
        slug
        hero {
          title
          body
          cta {
            label
            url
          }
          image
        }
        campaignSections {
          type
          image
          title
          header
          body
          resources {
            title
            header
            body
            ctaList {
              label
              url
              file
            }
            image
            imageSide
          }
          leftCol {
            header
            body
            image
            cta {
              label
              url
              file
            }
          }
          rightCol {
            header
            body
            image
            cta {
              label
              url
              file
            }
          }
          cta {
            label
            url
            file
          }
        }
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
  }
`;

export default Campaign;
