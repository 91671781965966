import React from 'react';
import cn from 'classnames';
import CtaButton from 'components/atoms/CtaButton';
import { md2react } from 'helpers/parser';

interface HeroProps {
  brandLogo?: string;
  heading: string;
  content: string;
  cta: {
    url: string;
    label: string;
  };
  footnote?: string;
  imageData?: {
    src: string;
    srcSet: string;
  };
  image?: string;
  cards?: any[];
  reverse?: boolean;
  mask?: boolean;
  style?: any;
  className?: any;
  calloutSceneWidths?: string;
  onSpeakClick?: any;
}

const Hero: React.FC<HeroProps> = ({
  heading,
  content,
  cta,
  image,
  imageData,
  className,
  onSpeakClick,
}) => {
  return (
    <div className={cn('text-dark-grey bg-gray-100 pt-24', className)}>
      <div className="u-container mx-auto flex flex-wrap items-center justify-start px-14">
        <div className="w-full lg:w-1/2">
          <div className="mx-auto px-8 pt-16 lg:py-24 xl:py-28">
            <h1 className="mb-8 u-h1">{heading}</h1>

            <div className="mb-8 prose-lg lg:prose-xl">{md2react(content)}</div>

            {cta?.url === 'GO_TO_FORM' ? (
              <button
                className="mb-16 u-btn u-btn--green focus:outline-none"
                onClick={onSpeakClick}
              >
                {cta?.label}
              </button>
            ) : (
              <CtaButton to={cta?.url} label={cta?.label} inverted />
            )}
          </div>
        </div>

        <div className={cn('w-full lg:w-1/2 h-full relative pb-16')}>
          <img
            src={image}
            alt={heading}
            className="object-contain transform scale-125"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
