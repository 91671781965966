import React from 'react';
import { Link } from 'gatsby';
import { md2react } from 'helpers/parser';
import Section from 'components/atoms/Section';
import CtaButton from 'components/atoms/CtaButton';
import CampaignResource from 'components/molecules/CampaignResource';
import ViewCampaignButton from 'components/atoms/ViewCampaignButton';

interface CampaignSectionsProps {
  image: string;
  title: string;
  header: string;
  body: string;
  cta: any;
  leftCol: any;
  rightCol: any;
  resources: [];
  onSpeakClick?: any;
}

export const CampaignCenterSection: React.FC<CampaignSectionsProps> = ({
  image,
  title,
  header,
  body,
  cta,
}) => {
  return (
    <Section>
      <div className="max-w-lg mx-auto">
        <img className="w-full h-auto" src={image} alt={title} />
      </div>
      <div className="uppercase text-aqua mb-1 font-bold text-xl">{title}</div>
      <h3 className="u-h3 mt-2 md:px-40">{header}</h3>
      <div className="my-10 mx-auto max-w-screen-lg prose-xl leading-tight lg:prose-2xl lg:leading-snug">
        {md2react(body)}
      </div>
      <ViewCampaignButton cta={cta} />
    </Section>
  );
};

export const CampaignTwoColSection: React.FC<CampaignSectionsProps> = ({
  header,
  leftCol,
  rightCol,
}) => {
  return (
    <Section className="bg-gray-100 " title={header} titleFontSize="u-h1">
      <div className="pt-10 flex flex-col md:flex-row">
        <div className="flex-1 max-w-md mx-auto">
          <img
            src={leftCol?.image}
            alt={leftCol?.header}
            className="w-full h-auto"
          />

          <div className="mt-11 text-center md:hidden">
            <h2 className="mb-3.5 u-h2">{leftCol?.header}</h2>
            <p className="text-xl my-10 text-paragraph mx-auto max-w-md">
              {leftCol?.body}
            </p>
            <ViewCampaignButton cta={leftCol?.cta} />
          </div>
        </div>
        <div className="flex-1 max-w-md mx-auto mt-12 md:mt-0">
          <img
            src={rightCol?.image}
            alt={rightCol?.header}
            className="w-full h-auto"
          />

          <div className="mt-11 text-center md:hidden">
            <h2 className="mb-3.5 u-h2">{rightCol?.header}</h2>
            <p className="text-xl my-10 text-paragraph mx-auto max-w-md">
              {rightCol?.body}
            </p>
            <ViewCampaignButton cta={rightCol?.cta} />
          </div>
        </div>
      </div>

      <div className="hidden md:flex flex-row justify-between">
        <div className="flex-1">
          <div className="mt-11 text-center">
            <h2 className="my-3.5 u-h2">{leftCol?.header}</h2>
            <p className="text-xl my-10 text-paragraph mx-auto max-w-sm">
              {leftCol?.body}
            </p>
            <ViewCampaignButton cta={leftCol?.cta} />
          </div>
        </div>
        <div className="flex-1">
          <div className="mt-11 text-center">
            <h2 className="my-3.5 u-h2">{rightCol.header}</h2>
            <p className="text-xl my-10 text-paragraph mx-auto max-w-sm">
              {rightCol.body}
            </p>
            <ViewCampaignButton cta={rightCol?.cta} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export const CampaignBulletSection: React.FC<CampaignSectionsProps> = ({
  title,
  header,
  image,
  body,
  cta,
}) => {
  return (
    <Section>
      <div className="uppercase text-aqua mb-1 font-bold text-xl">{title}</div>
      <h1 className="u-h1  mt-4 mb-10">{header}</h1>
      <div className="md:flex">
        <Link className="max-w-lg mx-auto pb-10" to={cta?.url}>
          <img className="w-full h-auto" src={image} alt={title} />
        </Link>
        <div className="ml-10 text-left">
          <div className="prose-xl mb-4">{md2react(body)}</div>
          <ViewCampaignButton cta={cta} />
        </div>
      </div>
    </Section>
  );
};

export const CampaignResourcesSection: React.FC<CampaignSectionsProps> = ({
  resources,
}) => {
  return (
    <Section verticalPadding="max-w-screen-2xl my-20">
      {resources?.map(
        ({ title, header, body, ctaList, image, imageSide }, index) => (
          <CampaignResource
            key={index}
            centered={false}
            title={title}
            header={header}
            body={body}
            cta={ctaList}
            image={image}
            imageSide={imageSide}
            titleColor={'text-aqua'}
          />
        ),
      )}
    </Section>
  );
};

export const CampaignCtaSection: React.FC<CampaignSectionsProps> = ({
  header,
  cta,
  onSpeakClick,
}) => {
  return (
    <div className="bg-primary-light">
      <div className="container max-w-screen-xl px-10 pt-24 pb-20 mx-auto text-center md:px-20 lg:px-40 xl:px-64 md:pt-48 md:pb-40">
        <h1 className="u-h1 text-white mb-9">{header}</h1>

        {cta?.url === 'GO_TO_FORM' ? (
          <button
            className="mb-16 u-btn u-btn--white focus:outline-none"
            onClick={onSpeakClick}
          >
            {cta?.label}
          </button>
        ) : (
          <CtaButton to={cta?.url} label={cta?.label} inverted />
        )}
      </div>
    </div>
  );
};
