import React from 'react';
import cn from 'classnames';
import { md2react, html2react } from 'helpers/parser';

interface SectionProps {
  className?: string;
  verticalPadding?: string;
  inverted?: boolean;
  title?: string;
  titleFontSize?: string;
  body?: string;
  footnote?: string;
}

const Section: React.FC<SectionProps> = ({
  className,
  verticalPadding = 'py-16 lg:py-24 xl:py-28',
  inverted,
  title,
  titleFontSize = 'u-h2',
  body,
  footnote,
  children,
}) => (
  <div className={className}>
    <div
      className={cn('u-container', inverted ? 'text-white' : 'text-dark-grey')}
    >
      <div
        className={cn(
          'max-w-screen-lg mx-auto px-6 text-center',
          verticalPadding,
        )}
      >
        {title && (
          <h2 className={cn('mx-auto max-w-screen-md mb-8', titleFontSize)}>
            {html2react(title)}
          </h2>
        )}

        {body && (
          <div className="mx-auto max-w-screen-md prose-lg leading-tight lg:prose-xl lg:leading-snug">
            {md2react(body)}
          </div>
        )}

        {footnote && (
          <div className="mx-auto max-w-screen-md mt-4 prose-sm leading-snug">
            {md2react(footnote)}
          </div>
        )}

        {children && <div className="mt-8">{children}</div>}
      </div>
    </div>
  </div>
);

export default Section;
