import React from 'react';
import * as Yup from 'yup';
import useFetch from 'use-http';
import useHubspot from 'hooks/use-hubspot';

export interface CampaignFormSection {
  firstname: string;
  lastname: string;
  email: string;
  company: string;
  jobtitle: string;
}

const initialValues: CampaignFormSection = {
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  jobtitle: '',
};

const schema = Yup.object().shape({
  firstname: Yup.string().required('This field is required'),
  lastname: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Please provide valid email')
    .required('This field is required'),
  company: Yup.string().required('This field is required'),
  jobtitle: Yup.string().required('This field is required'),
});

const CampaignFormSection: React.FC<CampaignFormSection> = ({
  header,
  forceSuccess,
}) => {
  const { post, error, response } = useFetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7551458/bca4c4b8-d849-40f7-b850-07bccb27c24d',
  );

  const gtmVariables = {
    event: 'Lead Form Submission Succeeded',
    'gtm.elementClasses': 'lead-form',
    'gtm.elementId': 'lead-form-speak-with-us',
  };

  const {
    values: { firstname, lastname, email, company, jobtitle },
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    errors,
  } = useHubspot({ initialValues, schema, post, gtmVariables });

  const ErrorMessage = ({ name }) => {
    return errors[name] && touched[name] ? (
      <div className="text-sm text-red-500 -mt-8 mb-2">{errors[name]}</div>
    ) : null;
  };

  return (
    <div className="bg-aqua py-12 md:p-28">
      <div className="text-center max-w-screen-lg mx-auto" id="speakWithUsEnt">
        <h1 className="u-h1 text-white mb-12">{header}</h1>
        <div className="u-container bg-white max-w-3xl mx-auto p-10 text-left">
          <p className="text-2xl">Interested in learning more?</p>
          <p className="text-lg">
            Fill out the form below and our team will reach out.
          </p>
          {(response?.ok || forceSuccess) && (
            <div className="text-primary font-bold text-lg mt-3">
              Thank you! We will contact you shortly.
            </div>
          )}
          <form
            className="mt-5"
            action="/"
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <input
                  className="u-form-input"
                  type="text"
                  name="firstname"
                  id="firstname"
                  value={firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="First Name"
                />
                <ErrorMessage name="firstname" />
              </div>
              <div className="flex-1">
                <input
                  className="u-form-input"
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Last Name"
                />
                <ErrorMessage name="lastname" />
              </div>
            </div>

            <div className="md:flex md:space-x-5">
              <div className="flex-1">
                <input
                  className="u-form-input"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email Address"
                />
                <ErrorMessage name="email" />
              </div>
              <div className="flex-1">
                <input
                  className="u-form-input"
                  type="text"
                  name="company"
                  id="company"
                  value={company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Company Name"
                />
                <ErrorMessage name="company" />
              </div>
            </div>

            <div className="md:w-1/2 md:pr-3">
              <input
                className="u-form-input"
                type="text"
                name="jobtitle"
                id="jobtitle"
                value={jobtitle}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Title"
              />
              <ErrorMessage name="jobtitle" />
            </div>

            <div className="space-y-6 md:space-x-7 text-center">
              <button type="submit" className="u-btn u-btn--green">
                Speak with us
              </button>
            </div>

            {error && (
              <div className="text-red-500 mt-4">{response.data?.message}</div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CampaignFormSection;
